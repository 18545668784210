import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../utils/api.service';

@Injectable({
  providedIn: 'root'
})
export class RemoteConfigService {
  constructor (private api: ApiService) {}

  public synchronizeSchemas(): Observable<void> {
    return this.api.post('api/remote-configs/schemas', {});
  }
}