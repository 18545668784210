import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-expandable-mat-list-item',
  templateUrl: './expandable-mat-list-item.component.html',
  styleUrls: ['./expandable-mat-list-item.component.scss']
})
export class ExpandableMatListItemComponent implements OnInit {

  @Input()
  public expanded = false;

  @Input()
  public menuTitle: string;

  @Input()
  public icon: string;

  @Input()
  public nestingLevel = 1;

  constructor() { }

  ngOnInit() {
  }

  public expand(): void {
    this.expanded = !this.expanded;
  }

  public getNestingPadding(): any {
    return this.nestingLevel * 16;
  }
}
