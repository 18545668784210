import {
  Directive,
  TemplateRef,
  ViewContainerRef,
  Input,
  OnInit,
  OnDestroy
} from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { UserPermissionService, AuthorizeAction } from '@app/core';

@Directive({
  selector: '[appAuthorize]'
})
export class AuthorizeDirective implements OnInit, OnDestroy {

  private hasView = false;
  private subscription: Subscription;

  private authorizeActionSubject = new BehaviorSubject<AuthorizeAction>(null);
  private authorizeAction$ = this.authorizeActionSubject.asObservable();

  @Input() set appAuthorize(authorizeAction: AuthorizeAction) {
    this.authorizeActionSubject.next(authorizeAction);
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private userPermissionService: UserPermissionService
  ) {
  }

  ngOnInit() {
    this.subscribeOnFunctionalityChanges();
  }

  private subscribeOnFunctionalityChanges(): void {
    this.subscription = this.authorizeAction$
      .pipe(
        filter(authorizeAction => authorizeAction != null)
      ).subscribe(authorizeAction => {
        this.userPermissionService.hasPermission(authorizeAction).subscribe((response: boolean) => {
          this.toggleEmbeddedView(response);
        });
      });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private toggleEmbeddedView(hasPermission: boolean): void {
    if (hasPermission && !this.hasView) {
      this.createEmbeddedView();
    } else if (!hasPermission && this.hasView) {
      this.clearEmbeddedView();
    }
  }

  private createEmbeddedView(): void {
    this.viewContainer.createEmbeddedView(this.templateRef);
    this.hasView = true;
  }

  private clearEmbeddedView(): void {
    this.viewContainer.clear();
    this.hasView = false;
  }

}
