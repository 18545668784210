import { Component, OnInit, Input, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-column-selector',
  templateUrl: './column-selector.component.html',
  styleUrls: ['./column-selector.component.scss']
})
export class ColumnSelectorComponent implements OnInit {

  @Input()
  public tableName: string = '';

  @Input()
  public columns: string[] = [];

  @Output()
  public changeColumns = new Subject<string[]>();

  public selectedColumns: string[] = [];

  ngOnInit() {
    const storageColumns = this.getColumnsFromLocalStorage();
    storageColumns
      ? this.selectedColumns = [...storageColumns]
      : this.selectedColumns = [...this.columns];
    this.emitChangeEvent();
  }

  public onChange(event: MatCheckboxChange): void {
    const value = event.source.value;
    event.checked
      ? this.addColumn(value)
      : this.removeColumn(value);
    this.emitChangeEvent();
    this.updateLocalStorage();
  }

  public addColumn(value: string): void {
    this.selectedColumns.splice(this.getIndex(value), 0, value);
  }

  private getIndex(val: string): number {
    let currentIndex = this.columns.indexOf(val);
    let lowestExistingIndex = 0;
    while (lowestExistingIndex == 0) {
      if (currentIndex < 0) {
        return 0;
      }
      this.selectedColumns.forEach(column => {
        if (this.columns[currentIndex] == column) {
          lowestExistingIndex = currentIndex + 1;
        }
      });
      currentIndex--;
    }
    return lowestExistingIndex;
  }

  public removeColumn(value: string): void {
    const index = this.selectedColumns.indexOf(value);
    this.selectedColumns.splice(index, 1);
  }

  public isChecked(value: string): boolean {
    return this.selectedColumns.some(s => s === value);
  }

  private emitChangeEvent(): void {
    this.changeColumns.next(this.selectedColumns);
  }

  private updateLocalStorage(): void {
    localStorage.setItem(this.tableName, JSON.stringify(this.selectedColumns));
  }

  private getColumnsFromLocalStorage(): string[] {
    return JSON.parse(localStorage.getItem(this.tableName));
  }
}
