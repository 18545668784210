<div>
    <h3>{{ 'legalDetails' | translate }}</h3>
    <form [formGroup]="formGroup" fxLayout="column">

        <mat-form-field color="accent">
            <mat-label>{{ 'idno' | translate }}</mat-label>
            <input matInput formControlName="idno">
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'tax' | translate}}</mat-label>
            <input matInput formControlName="tax">
        </mat-form-field>

    </form>

     <!-- Actions -->
  <div mat-dialog-actions fxLayoutAlign="end">
    <button mat-button (click)="onCancel()">{{ 'cancel' | translate }}</button>
    <button mat-raised-button (click)="onSave()" color="accent">{{ 'save' | translate }}</button>
  </div>
</div>