import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Role } from '../../models';
import { ApiService } from '../utils';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  constructor(private api: ApiService) { }

  public create(role: Role): Observable<Role> {
    return this.api.post<Role>(`api/roles`, role);
  }

  public get(): Observable<Role[]> {
    return this.api.get<Role[]>('api/roles');
  }
}
