<!-- Header -->
<div mat-dialog-title>
  <p *ngIf="(headerMessage$ | async) as headerMessage; else defaultHeader">{{ headerMessage }}</p>
  <ng-template #defaultHeader>
    <p>{{ 'confirmationDialog.default.header' | translate }}</p>
  </ng-template>
</div>

<!-- Content and notes -->
<div mat-dialog-content>
  <div *ngIf="!hideContent">
    <p *ngIf="(content$ | async) as content; else defaultContent">{{ content }}</p>
    <ng-template #defaultContent>
      <p>{{ 'confirmationDialog.default.content' | translate }}</p>
    </ng-template>
  </div>
  <p class="notes" *ngIf="(notes$ | async) as notes">{{ notes }}</p>
  <p class="warning" [innerHtml]="warning$ | async"></p>
</div>

<!-- Actions -->
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
  <button mat-flat-button type="button" [mat-dialog-close]="false">{{ cancel$ | async }}</button>
  <button mat-flat-button color="accent" [mat-dialog-close]="true" type="button"
    cdkFocusInitial>{{ confirm$ | async }}</button>
</div>