import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { PagedResult, Subscription } from '@app/core';

@Component({
  selector: 'app-subscriptions-accordion',
  templateUrl: './subscriptions-accordion.component.html',
  styleUrls: ['./subscriptions-accordion.component.scss']
})
export class SubscriptionsAccordionComponent {

  @Input() public pagedResult?: PagedResult<Subscription>;
  @Input() public currentLang?: string;
  @Output() public pageChangeEvent = new EventEmitter<PageEvent>();
  @Output() public editEvent = new EventEmitter<Subscription>()
  @Output() public deleteEvent = new EventEmitter<string>()
  @Output() public groupedProductsEvent = new EventEmitter<Map<string, string[]>>();

  public groupedProducts: Map<string, string[]> = new Map();
  
  ngOnChanges() {
    this.groupedProducts = this.groupProductsByCategory();
    this.groupedProductsEvent.emit(this.groupedProducts);
  } 

  ngOnInit() {
    if (this.pagedResult && this.pagedResult.data) {
      this.groupedProducts = this.groupProductsByCategory();
    }
  }
  
  public pageChanged(event: PageEvent): void {
    this.pageChangeEvent.emit(event);
  }

  public edit(s: Subscription){
    this.editEvent.emit(s);
  }

  public delete(id: string) {
    this.deleteEvent.emit(id);
  }

  private groupProductsByCategory(): Map<string, string[]> {
    const groupedProducts: Map<string, string[]> = new Map();

    for (const sub of this.pagedResult?.data || []) {
      for (const product of sub.products) {
        const key = `${product.category}`;
        const value = `${product.productType}`;

        if (groupedProducts.has(key)) {
          groupedProducts.get(key)?.push(value);
        } else {
          groupedProducts.set(key, [value]);
        }
      }
    }

    return groupedProducts;
  }

  public isCategoryPresent(category: string, products: any[]): boolean {
    return products.some(product => product.category === category);
  }

  public getProductTypesForCategory(category: string, products: any[]): string[] {
    return products
      .filter(product => product.category === category)
      .map(product => product.productType);
  }
}
