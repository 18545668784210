
<div fxLayout="column" fxLayoutgap="5px">
<mat-card class="p-8 bg-white mb-7">
  <mat-card-title class="mb-5">
    <h6>{{ 'digitalIdentityDetails' | translate }}</h6>
  </mat-card-title>

  <!-- Did -->
  <div class="mb-2" fxLayout="column" fxLayoutGap="5px">
    <label>{{ 'did' | translate }}</label>
    <div>
      <b>{{ company.did }}</b>
      <button mat-icon-button matSuffix ngxClipboard [cbContent]="company.did" class="ml-5">
        <label>{{ "copy" | translate}}</label>
        <mat-icon>content_copy</mat-icon>
    </button>
    </div>
  </div>

  <!-- VerKey -->
  <div class="mb-2"  fxLayout="column" fxLayoutGap="5px">
    <label>{{ 'verKey' | translate }}</label>
    <div>
      <b>{{ company.verKey }}</b>
      <button mat-icon-button matSuffix ngxClipboard [cbContent]="company.verKey" class="ml-5">
        <label>{{ "copy" | translate}}</label>
        <mat-icon>content_copy</mat-icon>
    </button>
    </div>
  </div>

  <!-- AgentURL -->
  <div class="mb-2" fxLayout="column" fxLayoutGap="5px">
    <label>{{ 'agentUrl' | translate }}</label>
    <div>
      <b>{{ company.agentUrl }}</b>
      <button mat-icon-button matSuffix ngxClipboard [cbContent]="company.agentUrl" class="ml-5">
        <label>{{ "copy" | translate}}</label>
        <mat-icon>content_copy</mat-icon>
    </button>
    </div>
  </div>
</mat-card>
<mat-card class="p-5 bg-white mb-7">
  <mat-card-title class="mb-5">
  <h6>{{ 'apiDetails' | translate }}</h6>
</mat-card-title>

  <!-- ApiKey -->
  <div class="mb-2" fxLayout="column" fxLayoutGap="5px">
    <label>{{ 'apiKey' | translate }}:</label>
    <div>
      <b>{{ company.apiKey }}</b>
      <button mat-icon-button matSuffix ngxClipboard [cbContent]="company.apiKey" class="ml-5">
        <label>{{ "copy" | translate}}</label>
        <mat-icon>content_copy</mat-icon>
    </button>
    </div>
  </div>

</mat-card>
<mat-card class="p-5 bg-white mb-7">
  <mat-card-title class="mb-5">
  <h6>{{ 'applicationDetails' | translate }}</h6>
</mat-card-title>

  <!-- Website -->
  <div class="mb-2" fxLayout="column" fxLayoutGap="5px">
    <label>{{ 'website' | translate }}</label>
    <div>
      <b>{{ company.website }}</b>
      <button mat-icon-button matSuffix ngxClipboard [cbContent]="company.website" class="ml-5">
        <label>{{ "copy" | translate}}</label>
        <mat-icon>content_copy</mat-icon>
    </button>
    </div>
  </div>

  <!-- Phone -->
  <div class="mb-2" fxLayout="column" fxLayoutGap="5px">
    <label>{{ 'phone' | translate }}</label>
    <div>
      <b>{{ company.phone }}</b>
      <button mat-icon-button matSuffix ngxClipboard [cbContent]="company.phone" class="ml-5">
        <label>{{ "copy" | translate}}</label>
        <mat-icon>content_copy</mat-icon>
    </button>
    </div>
  </div>

  <!-- Email -->
  <div class="mb-2" fxLayout="column" fxLayoutGap="5px">
    <label>{{ 'email' | translate }}</label>
    <div>
      <b>{{ company.email }}</b>
      <button mat-icon-button matSuffix ngxClipboard [cbContent]="company.email" class="ml-5">
        <label>{{ "copy" | translate}}</label>
        <mat-icon>content_copy</mat-icon>
    </button>
    </div>
  </div>

   <!-- Name translations -->
  <div class="mb-2" fxLayout="column" fxLayoutGap="5px">
    <label>{{ 'nameTranslations' | translate }}</label>
    <app-translated-property [translations]="company.nameTranslations"></app-translated-property>
  </div>
</mat-card>
</div>