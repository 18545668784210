import { Component, ViewChild } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatSidenavContainer } from '@angular/material/sidenav';

import { AuthService, SpinnerService } from './modules/core/services';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  @ViewChild('sidenavContainer', { static: true })
  public sidenavContainer?: MatSidenavContainer;

  public isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );

  public loading = false;
  public authenticationRoute = '/authentication';

  constructor(
    private breakpointObserver: BreakpointObserver,
    private translateService: TranslateService,
    private authService: AuthService,
    private spinnerService: SpinnerService,
    private router: Router
  ) {
    translateService.setDefaultLang('en');
    translateService.use('en');
  }

  ngOnInit() {
    this.subscribeOnRouteChanges();
    this.subscribeOnSpinnerEvents();
  }

  private subscribeOnRouteChanges(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Check if unauthorized route is previous
        if (event.url.startsWith(this.authenticationRoute)) {
          this.spinnerService.stop();
        }
      }
    });
  }

  private subscribeOnSpinnerEvents(): void {
    this.spinnerService.loading$.subscribe(loading => {
      this.loading = loading;
    });
  }

  public showSidenav(): boolean {
    return this.authService.isAuthenticated();
  }

  public toggle(): void {
    if (this.sidenavContainer) {
      this.sidenavContainer.start.toggle();
    }
  }

  public closeSidenav(): void {
    // After click on nav menu item closes the sidenav on mobile devices
    if (this.sidenavContainer?.start?.mode === 'over') {
      this.sidenavContainer.start.close();
    }
  }
}
