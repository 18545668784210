import { Category } from "./category.model";

export class Company {
  constructor(
    public id: string,
    public email: string,
    public phone: string,
    public website: string,
    public agentUrl: string,
    public did: string,
    public verKey: string,
    public apiKey: string,
    public isDeleted: boolean,
    public createdAt: Date,
    public discriminator: string,
    public updatedAt: Date,
    public categories: Category[],
    public nameTranslations: any
  ) {}
}