import { Pipe, PipeTransform } from "@angular/core";
import moment from "moment";

@Pipe({
    name: 'localDate',
  })
  export class LocalDatePipe implements PipeTransform {
  
    constructor() { }
  
    transform(dateVal): string {
        return moment.utc(dateVal).toISOString();
    }
  }