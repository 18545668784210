import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedMaterialModule } from './shared-material.module';
import {
  ConfirmationDialogComponent,
  ColumnSelectorComponent,
  LoaderComponent,
  ImageDialogComponent,
  CreateCompanyDialogComponent,
  UpdateCompanyDialogComponent,
  TranslatedPropertyComponent,
  LogosComponent,
  LogoUploadComponent
} from './components';

import { AuthorizeDirective } from './directives';
import { UpdatePasswordEmployeeComponent } from './components/update-password-employee/update-password-employee.component';
import { LocalDatePipe, SafeHtmlPipe, CustomTranslatePipe } from './pipes';
import { ClipboardModule } from 'ngx-clipboard';
import { LegalDetailsComponent } from './components/legal-details/legal-details.component';
import { CreateOrUpdateLegalDetailsDialogComponent } from './components/create-or-update-legal-details-dialog/create-or-update-legal-details-dialog.component';
import { ImageUploaderComponent } from './components/image-uploader/image-uploader.component';
import { UpdateSubscriptionDialogComponent } from './components/update-subscription-dialog/update-subscription-dialog.component';
import { CompanyProfileComponent } from './components/company-profile/company-profile.component';
import { CompanyDetailsComponent } from './components/company-details/company-details.component';
import { CompanyLogosComponent } from './components/company-logos/company-logos.component';
import { CompanySubscriptionsComponent } from './components/company-subscriptions/company-subscriptions.component';
import { MatTableResponsiveModule } from './directives/mat-table-responsive/mat-table-responsive.module';
import { SubscriptionsAccordionComponent } from './components/subscriptions-accordion/subscriptions-accordion.component';

@NgModule({
  declarations: [
    ConfirmationDialogComponent,
    AuthorizeDirective,
    ColumnSelectorComponent,
    UpdatePasswordEmployeeComponent,
    SafeHtmlPipe,
    LoaderComponent,
    ImageDialogComponent,
    CreateCompanyDialogComponent,
    UpdateCompanyDialogComponent,
    TranslatedPropertyComponent,
    LogosComponent,
    LogoUploadComponent,
    LegalDetailsComponent,
    CreateOrUpdateLegalDetailsDialogComponent,
    ImageUploaderComponent,
    SubscriptionsAccordionComponent,
    UpdateSubscriptionDialogComponent,
    LocalDatePipe,
    CustomTranslatePipe,
    CompanyProfileComponent,
    CompanyDetailsComponent,
    CompanyLogosComponent,
    CompanySubscriptionsComponent
  ],
  entryComponents: [
    ConfirmationDialogComponent,
    ImageDialogComponent,
    UpdateSubscriptionDialogComponent
  ],
  imports: [
    CommonModule,
    SharedMaterialModule,
    TranslateModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    ClipboardModule,
    MatTableResponsiveModule
  ],
  exports: [
    CommonModule,
    SharedMaterialModule,
    TranslateModule,
    ConfirmationDialogComponent,
    FlexLayoutModule,
    ReactiveFormsModule,
    FormsModule,
    AuthorizeDirective,
    ColumnSelectorComponent,
    UpdatePasswordEmployeeComponent,
    SafeHtmlPipe,
    LoaderComponent,
    ImageDialogComponent,
    ClipboardModule,
    TranslatedPropertyComponent,
    LogosComponent,
    LegalDetailsComponent,
    CreateOrUpdateLegalDetailsDialogComponent,
    SubscriptionsAccordionComponent,
    LocalDatePipe,
    CustomTranslatePipe,
    CompanyProfileComponent,
    CompanyDetailsComponent,
    CompanyLogosComponent,
    CompanySubscriptionsComponent,
    MatTableResponsiveModule
  ]
})
export class SharedModule { }
