import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { PagedResult, Subscription, SubscriptionInfo } from '../../models';
import { ApiService } from '../utils';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  constructor(private api: ApiService) { }

  public create(subscription: Subscription): Observable<Subscription> {
    return this.api.post('api/subscriptions', subscription);
  }

  public update(subscription: Subscription): Observable<void> {
    return this.api.put(`api/subscriptions/${subscription.id}`, subscription);
  }

  public delete(id: string): Observable<void> {
    return this.api.delete(`api/subscriptions/${id}`);
  }

  public getById(id: string): Observable<Subscription> {
    return this.api.get(`api/subscriptions/${id}`);
  }

  public get(request: any): Observable<PagedResult<Subscription>> {
    let params = new HttpParams();
    Object.keys(request).forEach((key) => {
      if (request[key]) {
        params = params.set(key, request[key].toString());
      }
    });
    return this.api.get('api/subscriptions', { params });
  }

  public getInfo(): Observable<SubscriptionInfo[]> {
    return this.api.get('api/subscriptions/info');
  }
}
