import { Component, Input, OnInit } from '@angular/core';
import { Company } from '@app/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-company-details',
  templateUrl: './company-details.component.html',
  styleUrls: ['./company-details.component.scss']
})
export class CompanyDetailsComponent implements OnInit{

  @Input()
  public company?: Company;

  categoriesString: string | undefined = '';
  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    const currentLang = this.translateService.currentLang;
    this.categoriesString = 
    (this.company?.categories.map(c => c.nameTranslations[currentLang] ? c.nameTranslations[currentLang] : c.name))?.join(', ');
  }
}
