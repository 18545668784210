import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  public baseUrl: string;

  constructor(private httpClient: HttpClient, private configService: ConfigService) {
    this.baseUrl = ConfigService.settings.baseApiUrl;
  }

  public get<T>(path: string, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    },
    params?: HttpParams | {
      [param: string]: string | string[];
    }
  }): Observable<T> {
    return this.httpClient.get<T>(this.baseUrl + path, options);
  }

  public post<T>(path: string, body: any, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    }
  }): Observable<T> {
    return this.httpClient.post<T>(this.baseUrl + path, body, options);
  }

  public delete<T>(path: string, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<T> {
    return this.httpClient.delete<T>(this.baseUrl + path, options);
  }

  public put<T>(path: string, body: any, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    },
    params?: HttpParams | {
      [param: string]: string | string[];
    }
  }): Observable<T> {
    return this.httpClient.put<T>(this.baseUrl + path, body, options);
  }

  public download(path: string, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'blob';
    withCredentials?: boolean;
  }): Observable<Blob> {
    return this.httpClient.get(this.baseUrl + path, { responseType: 'blob' });
  }
}
