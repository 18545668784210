export class LegalDetails {
  constructor(
    public id: string,
    public createdAt: Date,
    public updatedAt: Date,

    public idno: string,
    public tax: string,
    public companyId: string
  ) {}
}