export class Subscription {
  constructor(
    public id: string,
    public isTrial: boolean,
    public createdAt: Date,
    public name: string,
    public companyId: string,
    public companyNameTranslations: {},
    public products: any,
    public type: string,
    public billingType: string,
    public minVolume?: number,
    public price?: number,
    public updatedAt?: Date,
    public billingDate?: Date,
    public category?: string
  ) {}
}
