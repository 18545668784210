<div class="card-body" fxLayout="column">
    <mat-accordion [togglePosition]="'before'">
      <mat-expansion-panel class="mb-10" *ngFor="let sub of pagedResult?.data">
        <mat-expansion-panel-header>
          <mat-panel-title class="subscription-title">
            <b>{{ sub.name }}</b>
          </mat-panel-title>
          <mat-panel-title class="subscription-title billing ml-5">
            <b>{{ "billingDate" | translate }}</b>
            <mat-panel-description class="ml-2">
              {{ sub.billingDate | date: 'dd.MM.yyyy' }}
            </mat-panel-description>
          </mat-panel-title>
          <mat-panel-title class="subscription-title type">
            <b>{{"subscriptionType" | translate}}</b>
            <mat-panel-description class="ml-2">
              {{ sub.billingType | translate }}
            </mat-panel-description>
          </mat-panel-title>
          <div class="w-100 d-flex justify-content-end">
          <mat-panel-title>
            <mat-panel-description class="ml-2">
               <button mat-icon-button [matMenuTriggerFor]="editMenu" (click)="$event.stopPropagation()">
                    <mat-icon>more_horiz</mat-icon>
                </button>
                <mat-menu #editMenu="matMenu">
                    <!-- Edit -->
                    <button mat-menu-item (click)="edit(sub)">
                        <mat-icon>edit</mat-icon>
                        <span>{{ 'edit' | translate }}</span>
                    </button>
                    <!-- Delete -->
                    <button mat-menu-item (click)="delete(sub.id)">
                        <mat-icon>delete</mat-icon>
                        <span>{{ 'delete' | translate }}</span>
                    </button>
                </mat-menu>
            </mat-panel-description>
          </mat-panel-title>
        </div>
        </mat-expansion-panel-header>
        <div class="subscription-content">
          <div class="d-flex">
            <mat-panel-description class="flex-grow-0 mb-1">
              <mat-panel-title><b>{{"paymentType" | translate}}</b></mat-panel-title>
              <span>{{ sub.type | translate}}</span>
            </mat-panel-description>
            <mat-panel-description>
              <mat-panel-title><b>{{"minVolume" | translate}}</b></mat-panel-title>
              <span>{{ sub.minVolume | translate}}</span>
            </mat-panel-description>
          </div>
          <mat-panel-description class="mb-2">
            <mat-panel-title><b>{{"price" | translate}}</b></mat-panel-title>
            <span>{{ sub.price | translate}}</span>
          </mat-panel-description>
          <ng-container *ngFor="let entry of groupedProducts | keyvalue">
            <mat-panel-description *ngIf="isCategoryPresent(entry.key, sub.products)" class="mb-4">
              <mat-panel-title class="d-flex align-items-center"><b>{{ entry.key }}</b></mat-panel-title>
              <ng-container *ngFor="let productType of getProductTypesForCategory(entry.key, sub.products); last as last">
                <span class="subscription-product-type">
                  {{ productType | translate}}
                </span>
                <span *ngIf="!last" class="ml-2"></span>
              </ng-container>
            </mat-panel-description>
          </ng-container>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>