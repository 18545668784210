import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { ApiService } from '../utils/api.service';
import { LoginResult, LoginRequest } from '../../models';
import { UserPermissionService } from './user-permission.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private sidClaim = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/sid';

  constructor(
    private api: ApiService,
    private jwtHelper: JwtHelperService,
    private userPermissionService: UserPermissionService
  ) { }

  public login(user: LoginRequest): Observable<LoginResult> {
    return this.api.post<LoginResult>('api/sessions', user).pipe(
      tap((loginResult: LoginResult) => {
        this.userPermissionService.initialize();
        this.setTokens(loginResult);
      })
    );
  }

  public logout(): Observable<void> {
    return this.api.delete<void>('api/sessions').pipe(
      tap(() => {
        this.clearLocalStorage();
      })
    );
  }

  public refresh(): Observable<LoginResult> {
    return this.api.put<LoginResult>('api/sessions', { accessToken: this.getAccessToken() }).pipe(map(result => {
      if (result && result.accessToken) {
        localStorage.setItem('access-token', result.accessToken);
      } else {
        this.logout();
      }
      return result;
    }));
  }

  public clearLocalStorage(): void {
    localStorage.removeItem('access-token');
  }

  public isAuthenticated(): boolean {
    const token = this.getAccessToken();
    return token !== null;
  }

  public getId(): string {
    const token = this.jwtHelper.decodeToken(this.getAccessToken());
    return token.nameid;
  }

  public getSessionId(): string {
    const token = this.jwtHelper.decodeToken(this.getAccessToken());
    return token[this.sidClaim];
  }

  private setTokens(tokens: LoginResult): void {
    localStorage.setItem('access-token', tokens.accessToken);
  }

  private getAccessToken(): string {
    return localStorage.getItem('access-token');
  }

  public isTokenExpired(): boolean {
    return this.jwtHelper.isTokenExpired(this.getAccessToken());
  }
}
