import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Company, Product, Subscription, SubscriptionType, selectDependentValidation, selectValidationRules } from '@app/core';
import { convertUTCDateToLocalDate } from '@app/shared';
import { Periods } from 'app/modules/core/constants/periods.const';

@Component({
  selector: 'app-update-subscription-dialog',
  templateUrl: './update-subscription-dialog.component.html',
  styleUrls: ['./update-subscription-dialog.component.scss']
})

export class UpdateSubscriptionDialogComponent {
  public formGroup: FormGroup;
  public periods = Periods.get();
  public types = SubscriptionType.get();
  public companies: Company[];
  public products: Product[];
  public currentLang: string;
  public subscription: Subscription;
  public searchControl = new FormControl();
  public filteredProducts: Product[];
  public groupedProducts: { [key: string]: Product[]; };
  public selectedCategories: any;
  public selectedProducts: any;
  public selectedProductCategories!: any[];
  
  constructor(
    private dialogRef: MatDialogRef<UpdateSubscriptionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { 
      subscription: Subscription,
      companies: Company[], 
      products: Product[], 
      currentLang: string,
     }
  ) {
    this.selectedCategories = [];
    this.subscription = data.subscription;
    this.companies = data.companies;
    this.products = data.products;
    this.currentLang = data.currentLang;
    this.filteredProducts = this.products.slice(); 
    this.groupedProducts = this.groupProductsByCategory(this.products)
    this.formGroup = new FormGroup({
      products: new FormControl(this.subscription?.products?.map(p => p.productId), [ Validators.required ]),
      name: new FormControl(this.subscription.name, [ Validators.required ]),
      companyId: new FormControl(this.subscription.companyId, [ Validators.required ]),
      billingType: new FormControl(this.subscription.billingType, [ Validators.required ]),
      type: new FormControl(this.subscription.type, [ Validators.required ]),
      billingDate: new FormControl(this.subscription.billingDate,  [ Validators.required ]),
      price: new FormControl(this.subscription.price),
      minVolume: new FormControl(this.subscription.minVolume),
    });
    this.formGroup.get('products')!.setAsyncValidators(selectDependentValidation(selectValidationRules, this.products));
    this.onSelectionChange();

    this.searchControl.valueChanges.subscribe((searchTerm) => {
    this.filteredProducts = this.filterProductsBySearchTerm(searchTerm);
     });
  }

  public onSelectionChange(): void {
    const selectedProductIds = this.formGroup.get('products')?.value || [];
    this.selectedProducts = this.products.filter((product) => selectedProductIds.includes(product.id));
  
    this.selectedProductCategories = Array.from(new Set(this.selectedProducts.map((product) => product.productCategory)));
  }

  public filteredProductsInGroup(categoryProducts: any): Product[] {
    const filteredProducts = categoryProducts.value.filter((product) => this.filteredProducts.includes(product));
    if (filteredProducts.length > 0 && !this.selectedCategories.includes(categoryProducts.key)) {
      this.selectedCategories.push(categoryProducts.key);
    }
    return filteredProducts;
  }

  private filterProductsBySearchTerm(searchTerm: string): Product[] {
    if (!searchTerm) {
      return this.products.slice();
    }
    return this.products.filter((product) =>
      product.productType.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }
  
  public groupProductsByCategory(products: Product[]): { [key: string]: Product[] } {
    const groupedProducts: { [key: string]: Product[] } = {};
  
    for (const product of products) {
      if (!groupedProducts[product.productCategory]) {
        groupedProducts[product.productCategory] = [];
      }
      groupedProducts[product.productCategory].push(product);
    }
    return groupedProducts;
  }

  public onCancel(): void {
    this.dialogRef.close();
  }

  public onSave(): void {
    if (this.formGroup.invalid) {
      return;
    }
    
    const subscription = this.formGroup.getRawValue();
    subscription.id = this.subscription.id;
    subscription.billingDate = convertUTCDateToLocalDate(new Date(subscription.billingDate));
    this.dialogRef.close(subscription);
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.formGroup.controls[controlName].hasError(errorName);
  }

  public getControlByName = (controlName: string) => {
    return this.formGroup.controls[controlName];
  }

}
