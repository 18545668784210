export class Invoice {
    public id?: string;
    public companyId?: string;
    public companyNameTranslations: any;
    public subscriptionId?: string;
    public subscriptionName?: string;
    public amount?: number;
    public status?: string;
    public type?: string;
    public fromDate?: Date
    public toDate?: Date
    public createdAt?: Date;
}