import { Injectable } from '@angular/core';
import { IdentityInfo } from '@app/core';
import { Observable } from 'rxjs';

import { ApiService } from '../utils';

@Injectable({
    providedIn: 'root'
})
export class DigitalIdentityService {

    constructor(private api: ApiService) { }

    public get(): Observable<IdentityInfo> {
        return this.api.get<IdentityInfo>('api/wallet');
    }
}
