export class Product {
  constructor(
    public id: string,
    public typeId: string,
    public type: string,
    public productType: string,
    public name: string,
    public isDeleted: boolean,
    public price: number,
    public productCategory: string,
    public createdAt: Date,
    public updatedAt?: Date
  ) {}
}
