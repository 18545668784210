import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Company, PagedResult } from '../../models';
import { ApiService } from '../utils';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CompaniesService {
  private companies?: Observable<Company[]>;
  private issuers?: Observable<Company[]>;
  private verifiers?: Observable<Company[]>;

  constructor(private api: ApiService) { }

  public getInfo(): Observable<Company[]> {
    if(!this.companies){ 
      this.companies = this.api.get(`api/companies/info`);
    }
    return this.companies;
  }
  public getIssuersInfo(): Observable<Company[]> {
    if(!this.issuers){
      this.issuers = this.api.get(`api/companies/issuers-info`);
    }
    return this.issuers;
  }

  public getVerifiersInfo(): Observable<Company[]> {
    if(!this.verifiers){
      this.verifiers = this.api.get(`api/companies/verifiers-info`);
    }
    return this.verifiers;
  }

  public get(request: any): Observable<PagedResult<Company>> {
    let httpParams = new HttpParams();
    Object.keys(request).forEach((key) => {
      if (request[key]) {
        httpParams = httpParams.set(key, request[key].toString());
      }
    });

    return this.api.get('api/companies', {  params: httpParams });
  }
}
