export class InvoiceStatus {
    public static readonly Paid = 'Paid';
    public static readonly NotPaid = 'NotPaid';
  
    public static get(): string[] {
      return [
        this.Paid,
        this.NotPaid
      ]
    }
  }