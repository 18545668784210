export class Connection{
    constructor(
        public id: string,
        public state: string,
        public createdAt: Date,
        public updatedAt: Date,
        public alias: string,
        public endpointUri: string,
        public multiPartyInvitation: boolean,
        public theirDid: boolean
    ) {}
}