<div>
  <mat-menu #appMenu="matMenu">
    <div class="columns" fxLayout="column" (click)="$event.stopPropagation()">
      <mat-checkbox *ngFor="let column of columns" [value]="column" (change)="onChange($event)"
        [checked]="isChecked(column)">
        {{ column | translate }}
      </mat-checkbox>
    </div>
  </mat-menu>
  <!-- <button mat-stroked-button color="accent" [matMenuTriggerFor]="appMenu" matBadge="{{ selectedColumns.length }}" matBadgeColor="accent">
    Filter Columns<mat-icon>filter_list</mat-icon>
  </button> -->
  <a mat-mini-fab color="default" [matMenuTriggerFor]="appMenu" matBadge="{{ selectedColumns.length }}"
    matBadgeColor="accent">
    <mat-icon>filter_list</mat-icon>
  </a>
</div>