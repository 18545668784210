import { LegalDetails } from './../../../core/models/api/legal-details.model';
import { LegalDetailsService } from './../../../core/services/api/legal-details.service';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CreateOrUpdateLegalDetailsDialogComponent } from '../create-or-update-legal-details-dialog/create-or-update-legal-details-dialog.component';
import { SnackBarService } from '@app/core';
import { ConfirmationDialogComponent } from '@app/shared';

@Component({
  selector: 'app-legal-details',
  templateUrl: './legal-details.component.html',
  styleUrls: ['./legal-details.component.scss']
})
export class LegalDetailsComponent implements OnInit {

  public legalDetails?: LegalDetails;
  public showCreateBtn: boolean = false;

  @Input()
  public id: string = "";

  constructor(private service: LegalDetailsService, 
    private snackbar: SnackBarService,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    this.fetchLegalDetails();
  }

  private fetchLegalDetails(): void {
    console.log("fetchingLegalDetails");
    this.service.getByCompanyId(this.id)
      .subscribe((details) => {
         this.legalDetails=details;
         this.showCreateBtn = false;
        }, (e) => {
          if(e.status == 404){
            this.showCreateBtn = true;
          }
        });
  }

  public onCreate(): void{
      this.dialog.open(CreateOrUpdateLegalDetailsDialogComponent, {
        width: '350px'
      }).afterClosed().subscribe((details: LegalDetails) => {
        if (details) {
          this.service.create(this.id, details).subscribe(() => {
            this.snackbar.openSuccess('createdSuccessfully');
            this.fetchLegalDetails();
          }, () => {
            this.snackbar.openError('creationFailed');
          });
        }
      });
  }
  public onUpdate(): void{
      this.dialog.open(CreateOrUpdateLegalDetailsDialogComponent, {
        width: '350px',
        data: this.legalDetails
      }).afterClosed().subscribe((details: LegalDetails) => {
        if (details) {
          this.service.update(this.id, details).subscribe(() => {
            this.snackbar.openSuccess('createdSuccessfully');
            this.fetchLegalDetails();
          }, () => {
            this.snackbar.openError('creationFailed');
          });
        }
      });
  }

  public onDelete(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {};
    dialogConfig.data.headerMessage = 'delete';
    dialogConfig.data.content = 'doYouWantToDeleteDetails';

    this.dialog.open(ConfirmationDialogComponent, dialogConfig)
      .afterClosed()
      .subscribe((response: boolean) => {
        if (response) {
          this.service.delete(this.id).subscribe(() => {
            this.snackbar.openSuccess('deletedSuccessfully');
            this.fetchLegalDetails();
          });
        }
      });
  }
  
}
