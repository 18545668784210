<form autocomplete="off" [formGroup]="formGroup" fxLayout="column" fxLayoutGap="20px" (submit)="onSubmit()">
  <!-- Password -->
  <div fxLayout.lt-md="column" fxLayoutGap.gt-md="20px">
    <mat-form-field color="accent" fxFlex="50">
      <input autocomplete="off" type="password" matInput placeholder="{{ 'newPassword' | translate }}" formControlName="password">
     
      <mat-error *ngIf="hasError('password', 'maxlength')">
        {{ 'maxLengthError' | translate:{ value: 254 } }}
      </mat-error>
      <mat-error *ngIf="hasError('password', 'minlength')">
        {{ 'minLengthError' | translate:{ value: 8 } }}
      </mat-error>
    </mat-form-field>

    <!-- Confirm password -->
    <mat-form-field color="accent" fxFlex="50">
      <input autocomplete="off" type="password" matInput placeholder="{{ 'confirmNewPassword' | translate }}"
        formControlName="confirmPassword" pattern="{{ getControlByName('password').value }}">
    
      <mat-error *ngIf="hasError('confirmPassword', 'maxlength')">
        {{ 'maxLengthError' | translate:{ value: 254 } }}
      </mat-error>
      <mat-error *ngIf="hasError('confirmPassword', 'minlength')">
        {{ 'minLengthError' | translate:{ value: 8 } }}
      </mat-error>
      <mat-error *ngIf="getControlByName('confirmPassword')?.errors?.pattern">
        {{ 'passwordsMustMatch' | translate }}
      </mat-error>
    </mat-form-field>
  </div>

  <!-- Actions -->
  <div fxLayoutAlign="end" fxLayoutGap="20px">
    <button mat-button type="button" (click)="onCancel()">{{ 'cancel' | translate }}</button>
    <button mat-raised-button type="submit" color="accent" [disabled]="loadingRequest" [class.spinner]="loadingRequest">{{ 'save' | translate }}</button>
  </div>
</form>