export class SaleOperation{
    public id?: string;
    public objectId?: string;
    public ownerId?: string;
    public count?: string;
    public productType?: string;
    public status?: string;
    public nameTranslations?: any;
    public createdAt?: Date;
    public step?: string;
}