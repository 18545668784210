import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Logo } from '../../models';
import { ApiService } from '../utils';

@Injectable({
  providedIn: 'root'
})
export class LogoService {

  constructor(private api: ApiService) { }

  public create(id: string, formData: FormData): Observable<Logo> {
    return this.api.post(`api/companies/${id}/logos`, formData);
  }

  public get(id: string): Observable<Logo[]> {
    return this.api.get(`api/companies/${id}/logos`);
  }

  public update(companyId: string, logoId: string, formData: FormData): Observable<Logo> {
    return this.api.put(`api/companies/${companyId}/logos/${logoId}`, formData);
  }
}
