import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {

  public headerMessage$: Observable<string>;
  public content$?: Observable<string>;
  public notes$?: Observable<string>;
  public warning$?: Observable<string>;
  public confirm$?: Observable<string>;
  public cancel$?: Observable<string>;

  public hideContent = false;
  public notesParameter?: boolean;

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
    private translateService: TranslateService
  ) {
    // If hideContent is true, don't extract translation message
    if (!data.hideContent) {
      this.content$ = this.getMessage(data.content);
    }

    if (data.notesParameter) {
      this.notesParameter = data.notesParameter;
    }

    this.headerMessage$ = this.getMessage(data.headerMessage, this.notesParameter);
    this.confirm$ = data.confirm ? this.getMessage(data.confirm) : this.getMessage('submit');
    this.cancel$ = data.cancel ? this.getMessage(data.cancel) : this.getMessage('cancel');

    if (data.hideContent) {
      this.hideContent = data.hideContent;
    }

    if (data.notes) {
      this.notes$ = this.getMessage(data.notes, this.notesParameter);
    }

    if (data.warning) {
      this.warning$ = this.getMessageWithParameters(data.warning, data.warningParameter);
    }
  }

  ngOnInit() {
    this.dialogRef.updateSize('400px');
  }

  private getMessage(identifier: string, value?: any): Observable<string> {
    return this.translateService.get(identifier, { value });
  }

  private getMessageWithParameters(identifier: string, parameter: any): Observable<string> {
    return this.translateService.get(identifier, parameter);
  }
}
