import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../api';
import { SpinnerService } from '../utils';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    public auth: AuthService,
    public router: Router,
    public spinnerService: SpinnerService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.auth.isTokenExpired()) {
      this.spinnerService.start();
    }

    if (!this.auth.isAuthenticated()) {
      this.router.navigate(['authentication'], { queryParams: { returnUrl: state.url } });
      return false;
    }
    return true;
  }
}
