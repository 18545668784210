import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';

import { ApiService } from '../utils';
import {
  PagedResult,
  Employee,
  BaseSearchRequest
} from '../../models';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

  constructor(private api: ApiService) { }

  public get(request: BaseSearchRequest): Observable<PagedResult<Employee>> {
    let httpParams = new HttpParams();
    Object.keys(request).forEach((key) => {
      if (request[key]) {
        httpParams = httpParams.set(key, request[key].toString());
      }
    });

    return this.api.get<PagedResult<Employee>>('api/employees', { params: httpParams });
  }

  public getById(id: string): Observable<Employee> {
    return this.api.get<Employee>(`api/employees/${id}`);
  }

  public create(employee: Employee): Observable<Employee> {
    return this.api.post<Employee>(`api/employees`, employee);
  }

  public update(employee: Employee): Observable<void> {
    return this.api.put<void>(`api/employees/${employee.id}`, employee);
  }

  public delete(id: string): Observable<void> {
    return this.api.delete<void>(`api/employees/${id}`);
  }

  public updatePassword(id: string, password: string): Observable<void> {
    return this.api.put<void>(`api/employees/${id}/password`, { password });
  }

  public mapToNotificationSystem(id: string): Observable<void> {
    return this.api.put<void>(`api/employees/${id}/notification-system`, {});
  }
}
