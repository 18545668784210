export class InvoiceType {
    public static readonly Income = 'Income';
    public static readonly Expense = 'Expense';
  
    public static get(): string[] {
      return [
        this.Income,
        this.Expense
      ]
    }
  }