import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PagedResult, Product } from '../../models';
import { ApiService } from '../utils';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private api: ApiService) { }

  public create(product: Product): Observable<Product> {
    return this.api.post('api/products', product);
  }

  public update(product: Product): Observable<void> {
    return this.api.put(`api/products/${product.id}`, product);
  }

  public delete(id: string): Observable<void> {
    return this.api.delete(`api/products/${id}`);
  }

  public getById(id: string): Observable<Product> {
    return this.api.get(`api/products/${id}`);
  }

  public get(request: any): Observable<PagedResult<Product>> {
    let params = new HttpParams();
    Object.keys(request).forEach((key) => {
      if (request[key]) {
        params = params.set(key, request[key].toString());
      }
    });
    return this.api.get('api/products', { params });
  }

  public getInfo(): Observable<Product[]> {
    return this.api.get(`api/products/info`);
  }

  public getProductCategories(): Observable<Product[]> {
    return this.api.get(`api/products/categories`);
  }
}
