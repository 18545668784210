<mat-toolbar>
	<app-logo style="height: 100%;"></app-logo>
</mat-toolbar>
<mat-nav-list>
	<div *ngIf="isAuthenticated(); else notAuthenticatedSection">
		<!-- Employees -->
		<a routerLink="employees" (click)="menuItemClick()">
			<mat-list-item routerLinkActive="active-menu-item">
				<mat-icon matListIcon>work_outline</mat-icon>
				<p mat-line>{{ 'employees' | translate }}</p>
			</mat-list-item>
		</a>

		<!-- Issuers -->
		<a routerLink="issuers" (click)="menuItemClick()">
			<mat-list-item routerLinkActive="active-menu-item">
				<mat-icon matListIcon>work_outline</mat-icon>
				<p mat-line>{{ 'issuers' | translate }}</p>
			</mat-list-item>
		</a>

		<!-- Verifiers -->
		<a routerLink="verifiers" (click)="menuItemClick()">
			<mat-list-item routerLinkActive="active-menu-item">
				<mat-icon matListIcon>fingerprint</mat-icon>
				<p mat-line>{{ 'verifiers' | translate }}</p>
			</mat-list-item>
		</a>

		<!-- Products -->
		<a routerLink="products" (click)="menuItemClick()">
			<mat-list-item routerLinkActive="active-menu-item">
				<mat-icon matListIcon>view_agenda</mat-icon>
				<p mat-line>{{ 'products' | translate }}</p>
			</mat-list-item>
		</a>

		<!-- Subscriptions -->
		<a routerLink="subscriptions" (click)="menuItemClick()">
			<mat-list-item routerLinkActive="active-menu-item">
				<mat-icon matListIcon>grading</mat-icon>
				<p mat-line>{{ 'subscriptions' | translate }}</p>
			</mat-list-item>
		</a>

		<!-- Schemas -->
		<a routerLink="schemas" (click)="menuItemClick()">
			<mat-list-item routerLinkActive="active-menu-item">
				<mat-icon matListIcon>assignment</mat-icon>
				<p mat-line>{{ 'schemas' | translate }}</p>
			</mat-list-item>
		</a>

		<!-- Digital Identity -->
		<a routerLink="identity" (click)="menuItemClick()">
			<mat-list-item routerLinkActive="active-menu-item">
				<mat-icon matListIcon>fingerprint</mat-icon>
				<p mat-line>{{ 'digitalIdentity' | translate }}</p>
			</mat-list-item>
		</a>

		<!-- Sale-Operations -->
		<a routerLink="sale-operations" (click)="menuItemClick()">
			<mat-list-item routerLinkActive="active-menu-item">
				<mat-icon matListIcon>paid</mat-icon>
				<p mat-line>{{ 'saleOperations' | translate }}</p>
			</mat-list-item>
		</a>

		<!-- Invoices -->
		<a routerLink="invoices" (click)="menuItemClick()">
			<mat-list-item routerLinkActive="active-menu-item">
				<mat-icon matListIcon>receipt</mat-icon>
				<p mat-line>{{ 'invoices' | translate }}</p>
			</mat-list-item>
		</a>

		<!-- Company Schemas -->
		<a routerLink="company-schemas" (click)="menuItemClick()">
			<mat-list-item routerLinkActive="active-menu-item">
				<mat-icon matListIcon>view_list</mat-icon>
				<p mat-line>{{ 'companySchemas' | translate }}</p>
			</mat-list-item>
		</a>
		
		<!-- System Configurations -->
		<app-expandable-mat-list-item menuTitle="{{ 'system' | translate }}" icon="settings">
			<!-- Configuration -->
			<a routerLink="system-roles" (click)="menuItemClick()">
				<mat-list-item routerLinkActive="active-menu-item">
					<p mat-line>{{ 'permissions' | translate }}</p>
				</mat-list-item>
			</a>	
		</app-expandable-mat-list-item>

		<!-- Logout -->
		<mat-list-item (click)="logout()">
			<mat-icon matListIcon>power_settings_new</mat-icon>
			<p mat-line>{{ 'logOut' | translate }}</p>
		</mat-list-item>
	</div>
	<ng-template #notAuthenticatedSection>
		<!-- Authentication module -->
		<mat-list-item *ngIf="!isAuthenticated()" routerLinkActive="active-menu-item" routerLink="authentication">
			<mat-icon matListIcon>forward</mat-icon>
			<p mat-line>{{ 'authentication' | translate }}</p>
		</mat-list-item>
	</ng-template>
</mat-nav-list>
