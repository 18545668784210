import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TimeService {

  public toLocalDate(date: Date): Date {
    const d = date.toString();
    if (!d.includes('GMT')) {
      const utcDate = new Date(date);
      const localDate = new Date(utcDate.toString() + ' UTC');
      return localDate;
    } else {
      return date;
    }
  }
}
