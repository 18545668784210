import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-translated-property',
  templateUrl: './translated-property.component.html',
  styleUrls: ['./translated-property.component.scss']
})
export class TranslatedPropertyComponent implements OnInit {

  @Input()
  public translations?: any;
  public displayedResult?: any[];

  constructor() {
  }

  ngOnInit(): void {
    this.displayedResult = Object.entries(this.translations);
  }

}
