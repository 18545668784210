export class SubscriptionType {
    public static readonly PayPerUse = 'PayPerUse';
    public static readonly PayPerVolume = 'PayPerVolume';

    public static get(): any[] {
      return [
        this.PayPerUse,
        this.PayPerVolume
      ];
    }
}