import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '../utils';
import { Permission } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class RolePermissionService {

  constructor(private api: ApiService) { }

  public get(roleId: string): Observable<Permission[]> {
    return this.api.get<Permission[]>(`api/roles/${roleId}/security-actions`);
  }

  public create(roleId: string, securityActionId: string, securityActionTypeId: string): Observable<void> {
    return this.api.post<void>(`api/roles/${roleId}/security-actions/${securityActionId}/types/${securityActionTypeId}`, {});
  }

  public delete(roleId: string, securityActionId: string, securityActionTypeId: string): Observable<void> {
    return this.api.delete<void>(`api/roles/${roleId}/security-actions/${securityActionId}/types/${securityActionTypeId}`);
  }
}
