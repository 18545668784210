import { SchemaAttribute } from "@app/core";

export class Schema {
  constructor(
      public id: string,
      public name: string,
      public version: string,
      public attributeNames: string[],
      public nameTranslations: any,
      public attributes: SchemaAttribute[],
      public isDeleted: boolean,
      public status: string,
      public createdAt: Date,
      public updatedAt: Date,
      public externalId: string,
      public credentialDefinitionId: string
  ){}
}
