import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiService, Invoice, PagedResult } from "@app/core";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class InvoicesService {

    constructor(private api: ApiService) { }

    public get(request: any): Observable<PagedResult<Invoice>> {
        let httpParams = new HttpParams();
        Object.keys(request).forEach((key) => {
            if (request[key]) {
                httpParams = httpParams.set(key, request[key].toString());
            }
        });
        return this.api.get<PagedResult<Invoice>>('api/invoices', { params: httpParams });
    }

    public markAsPaid(id: string): Observable<void> {
       return this.api.put<void>(`api/invoices/${id}/paid`, {});
    }
}