import { FormGroup, FormControl, Validators } from '@angular/forms';

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-create-or-update-legal-details-dialog',
  templateUrl: './create-or-update-legal-details-dialog.component.html',
  styleUrls: ['./create-or-update-legal-details-dialog.component.scss']
})
export class CreateOrUpdateLegalDetailsDialogComponent implements OnInit {

  public formGroup?: FormGroup;
  constructor(
    @Inject(MAT_DIALOG_DATA) public details: any,
    private dialogRef: MatDialogRef<CreateOrUpdateLegalDetailsDialogComponent>
  ) { }

  ngOnInit(): void {
    this.initFormGroup();
  }

  private initFormGroup(): void {
    this.formGroup = new FormGroup({
      idno: new FormControl(this.details ? this.details.idno : null, Validators.required),
      tax: new FormControl(this.details ? this.details.tax : null, Validators.required)
    });
  }

  public onCancel() {
    this.dialogRef.close();
  }

  public onSave() {
    this.formGroup?.markAllAsTouched();
    if (!this.formGroup?.valid) {
      return;
    }

    const details = this.formGroup.getRawValue();
    this.dialogRef.close(details);
  }
}
