import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiService, PagedResult, SaleOperation } from "@app/core";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class SaleOperationsService {

    constructor(private api: ApiService) { }

    public get(request: any): Observable<PagedResult<SaleOperation>> {
        let httpParams = new HttpParams();
        Object.keys(request).forEach((key) => {
            if (request[key]) {
                httpParams = httpParams.set(key, request[key].toString());
            }
        });
        return this.api.get<PagedResult<SaleOperation>>('api/sale-operations', { params: httpParams });
    }
}