<a mat-list-item (click)="expand()">
  <mat-icon matListIcon>
    {{ icon }}
  </mat-icon>
  <p mat-line>{{ menuTitle }}</p>
  <mat-icon *ngIf="!expanded">
    chevron_right
  </mat-icon>
  <mat-icon *ngIf="expanded">
    expand_more
  </mat-icon>
</a>
<mat-nav-list [ngStyle]="{ 'padding-left.px': getNestingPadding() }" *ngIf="expanded">
  <ng-content>

  </ng-content>
</mat-nav-list>