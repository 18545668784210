<div *ngIf="showCreateBtn">
  <button mat-raised-button color="accent" (click)="onCreate()">
    <mat-icon>add</mat-icon>
    {{ 'addDetails' | translate }}
  </button>
</div>

<div *ngIf="!showCreateBtn">
  <div fxLayout="column" fxLayoutGap="5px">

    <!-- Email -->
    <div fxLayout="row" fxLayoutGap="10px">
      <b>{{ 'idno' | translate }}:</b>
      <span>{{ legalDetails?.idno }}</span>
    </div>

    <!-- Phone -->
    <div fxLayout="row" fxLayoutGap="10px">
      <b>{{ 'phone' | translate }}:</b>
      <span>{{ legalDetails?.tax }}</span>
    </div>
  </div>
  <br>
  <div>  
    <div fxFlex="row" fxLayoutGap="16px">
      <button mat-raised-button color="accent" (click)="onUpdate()">
        <mat-icon>edit</mat-icon>
        {{ 'edit' | translate }}
      </button>
      <button id="delete-btn" mat-raised-button (click)="onDelete()">
        <mat-icon>delete</mat-icon>
        <span>{{ 'delete' | translate }}</span>
      </button>
    </div>
  </div>
</div>