import { Component, OnInit, OnDestroy, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription, Subject } from 'rxjs';

import { SnackBarService, AuthService } from '@app/core';
import { Permissions } from '../../permissions';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  public Permissions = Permissions;

  public ticketsCount: number;
  public assignmentsCount: number;

  @Output()
  public menuItemClicked = new Subject();

  public reindexing: boolean;


  constructor(
    private authService: AuthService,
    private snackbarService: SnackBarService,
    private router: Router,
  ) { }

  ngOnInit() {
  }

  public isAuthenticated(): boolean {
    return this.authService.isAuthenticated();
  }

  public logout(): void {
    this.authService.logout().subscribe(() => {
      this.router.navigateByUrl('/authentication');
      this.snackbarService.openSuccess('logoutSuccessfully');
    });
  }

  public menuItemClick(): void {
    this.menuItemClicked.next();
  }
}
