import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Permission } from '../../models';

import { ApiService } from '../utils';

@Injectable({
  providedIn: 'root'
})
export class UsersPermissionService {

  constructor(private api: ApiService) { }

  public enable(userId: string, actionId: string, actionTypeId: string): Observable<void> {
    return this.api.put<void>(`api/users/${userId}/permissions/${actionId}/enable`, { securityActionTypeId: actionTypeId });
  }

  public disable(userId: string, actionId: string, actionTypeId: string): Observable<void> {
    return this.api.put<void>(`api/users/${userId}/permissions/${actionId}/disable`, { securityActionTypeId: actionTypeId });
  }

  public get(userId: string): Observable<Permission[]> {
    return this.api.get<Permission[]>(`api/users/${userId}/permissions`);
  }
}
