export class LogoType {
  public static readonly Icon = { name: 'Icon', size: '100x100'};
  public static readonly Profile = { name: 'Profile', size: '480x240' };

  public static get(): any[] {
    return [
      this.Icon,
      this.Profile
    ];
  }
}