import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { PagedResult, Verifier } from '../../models';
import { ApiService } from '../utils';

@Injectable({
  providedIn: 'root'
})
export class VerifierService {

  constructor(private api: ApiService) { }

  public create(verifier: Verifier): Observable<Verifier> {
    return this.api.post('api/verifiers', verifier);
  }

  public getById(id: string): Observable<Verifier> {
    return this.api.get(`api/verifiers/${id}`);
  }

  public delete(id: string): Observable<Verifier> {
    return this.api.delete(`api/verifiers/${id}`);
  }

  public update(verifier: Verifier): Observable<Verifier> {
    return this.api.put(`api/verifiers/${verifier.id}`, verifier);
  }

  public get(request: any): Observable<PagedResult<Verifier>> {
    let params = new HttpParams();
    Object.keys(request).forEach((key) => {
      if (request[key]) {
        params = params.set(key, request[key].toString());
      }
    });

    return this.api.get('api/verifiers', { params });
  }
}
