import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { UserPermissionService } from '../api/user-permission.service';
import { AuthorizeAction } from '../../models';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PermissionGuard implements CanActivate {

  constructor(private router: Router, private userPermissionService: UserPermissionService) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.userPermissionService
      .hasPermission(route.data as AuthorizeAction)
      .pipe(map(response => {
        if (response) {
          return true;
        } else {
          this.router.navigateByUrl('/404?returnUrl=' + state.url);
          return false;
        }
      }));

      
  }
}
