import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { LegalDetails } from '../../models';
import { ApiService } from '../utils';

@Injectable({
  providedIn: 'root'
})
export class LegalDetailsService {

  constructor(private api: ApiService) { }

  public getByCompanyId(id: string): Observable<LegalDetails> {
    console.log("requset legal detaisl by company id: ", id);
    return this.api.get('api/companies/'+id+'/legal-details');
  }

  public create(id: string, details: LegalDetails): Observable<LegalDetails[]> {
    return this.api.post('api/companies/'+id+'/legal-details', details);
  }

  public update(id: string, details: LegalDetails): Observable<LegalDetails[]> {
    return this.api.put('api/companies/'+id+'/legal-details', details);
  }

  public delete(id: string): Observable<LegalDetails[]> {
    return this.api.delete('api/companies/'+id+'/legal-details');
  }
}
