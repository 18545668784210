import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { PagedResult, Subscription } from '../../models';
import { ApiService } from '../utils';

@Injectable({
  providedIn: 'root'
})
export class CompanySubscriptionService {

  constructor(private api: ApiService) {

  }

  public getById(companyId: string, subscriptionId: string): Observable<Subscription> {
    return this.api.get(`api/companies/${companyId}/subscriptions/${subscriptionId}`);
  }

  public get(id: string, request: any): Observable<PagedResult<Subscription>> {
    let params = new HttpParams();
    Object.keys(request).forEach((key) => {
      if (request[key]) {
        params = params.set(key, request[key].toString());
      }
    });
    return this.api.get(`api/companies/${id}/subscriptions`, { params });
  }
}
