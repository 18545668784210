import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Category, PagedResult } from '../../models';
import { ApiService } from '../utils/api.service';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {
  constructor (private api: ApiService) {}

  public getCategories(request: any): Observable<PagedResult<Category>> {
    let params = new HttpParams();
    Object.keys(request).forEach((key) => {
      if (request[key]) {
        params = params.set(key, request[key].toString());
      }
    });

    return this.api.get('api/categories', { params })
  }

  public create(category: Category): Observable<void> {
    return this.api.post('api/categories', category);
  }

  public delete(categoryId: string): Observable<void> {
    return this.api.delete(`api/categories/${categoryId}`);
  }

  public update(category: Category): Observable<void> {
    console.log(category);
    return this.api.put(`api/categories/${category.id}`, category);
  }
}
