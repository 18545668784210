import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { EmployeeService, SnackBarService } from '@app/core';

@Component({
  selector: 'app-update-password-employee',
  templateUrl: './update-password-employee.component.html',
  styleUrls: ['./update-password-employee.component.scss']
})
export class UpdatePasswordEmployeeComponent implements OnInit {

  @Input() employeeId: string = '';
  @Output() cancel = new EventEmitter();
  @Output() passwordChanged = new EventEmitter();
  public formGroup: FormGroup;
  public loadingRequest?: boolean;

  constructor(
    private snackbarService: SnackBarService,
    private employeeService: EmployeeService
  ) { 
    this.formGroup = new FormGroup({
      password: new FormControl(null, [
        Validators.minLength(8),
        Validators.maxLength(254)
      ]),
      confirmPassword: new FormControl(null, [
        Validators.minLength(8),
        Validators.maxLength(254)
      ]),
    });
  }

  ngOnInit() {
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.formGroup.controls[controlName].hasError(errorName);
  }

  public getControlByName = (controlName: string) => {
    return this.formGroup.controls[controlName];
  }

  public onCancel(): void {
    this.cancel.emit();
  }

  public onSubmit(): void {
    if (!this.formGroup.valid) {
      return;
    }
    this.loadingRequest = true;
    this.employeeService.updatePassword(this.employeeId, this.formGroup.controls.password.value).subscribe(
      () => {
        this.snackbarService.openSuccess('passwordChanged');
        this.loadingRequest = false;
        this.passwordChanged.emit();
      },
      (error: any) => {
        this.loadingRequest = false;
        this.snackbarService.openError('passwordChangeFailed');
      }
    );
  }
}
