import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-image-dialog',
  templateUrl: './image-dialog.component.html',
  styleUrls: ['./image-dialog.component.scss']
})
export class ImageDialogComponent {

  @Input()
  public url: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.url = this.data.url;
  }

}
