import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Issuer, PagedResult } from '../../models';
import { ApiService } from '../utils';

@Injectable({
  providedIn: 'root'
})
export class IssuerService {

  constructor(private api: ApiService) { }

  public create(issuer: Issuer): Observable<void> {
    return this.api.post('api/issuers', issuer);
  }

  public getById(id: string): Observable<Issuer> {
    return this.api.get(`api/issuers/${id}`);
  }

  public delete(id: string): Observable<Issuer> {
    return this.api.delete(`api/issuers/${id}`);
  }

  public update(issuer: Issuer): Observable<void> {
    return this.api.put(`api/issuers/${issuer.id}`, issuer);
  }

  public get(request: any): Observable<PagedResult<Issuer>> {
    let params = new HttpParams();
    Object.keys(request).forEach((key) => {
      if (request[key]) {
        params = params.set(key, request[key].toString());
      }
    });

    return this.api.get('api/issuers', { params });
  }
}
