export class Periods {
  public static readonly Monthly = 'Monthly';
  public static readonly Yearly = 'Yearly';

  public static get(): any[] {
    return [
      this.Monthly,
      this.Yearly
    ];
  }
}