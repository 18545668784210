import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

export const AppRoutes: Routes = [
  {
    path: 'authentication',
    loadChildren: () => import('./modules/features/authentication/authentication.module')
      .then(t => t.AuthenticationModule)
  },
  {
    path: '',
    // canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/features/_layout/layout.module').then((m) => m.LayoutModule),
  },
  {
    path: 'identity',
    loadChildren: () => import('./modules/features/digital-identity/digital-identity.module')
    .then(t => t.DigitalIdentityModule)
  },
  {
    path: 'sale-operations',
    loadChildren: () => import('./modules/features/sale-operations/sale-operations.module')
    .then(t => t.SaleOperationsModule)
  },
  {
    path: 'invoices',
    loadChildren: () => import('./modules/features/invoices/invoices.module')
    .then(t => t.InvoicesModule)
  },
  {
    path: 'company-schemas',
    loadChildren: () => import('./modules/features/company-schemas/company-schemas.module')
    .then(t => t.CompanySchemasModule)
  },
  {
    path: 'categories',
    loadChildren: () => import('./modules/features/categories/categories.module')
    .then(t => t.CategoriesModule)
  },
  {
    path: '404',
    loadChildren: () => import('./modules/features/not-found/not-found.module')
      .then(t => t.NotFoundModule)
  },
  {
    path: '**',
    redirectTo: '404'
  }
];
