export class User {
  public id = '';
  public fullName = '';
  public username = '';
  public email  ='';
  public password = '';
  public isDeleted = false;
  public roleId= "";
  public mappedWithNotificationSystem = false;;
}
