import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import {
  SidebarComponent,
  LogoComponent,
  ExpandableMatListItemComponent
} from './components';
import { SharedModule } from '@app/shared';

@NgModule({
  declarations: [
    SidebarComponent,
    LogoComponent,
    ExpandableMatListItemComponent,
  ],
  imports: [
    SharedModule,
    RouterModule
  ],
  exports: [
    SidebarComponent
  ]
})
export class LayoutModule { }
