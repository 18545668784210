import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '../utils';
import { HttpParams } from '@angular/common/http';
import { PagedResult } from '../../models/utils';
import { Connection } from '../../models/api';

@Injectable({
    providedIn: 'root'
})
export class ConnectionsService {

    constructor(private api: ApiService) { }

    public get(request: any): Observable<PagedResult<Connection>> {
        let httpParams = new HttpParams();
        Object.keys(request).forEach((key) => {
            if (request[key]) {
                httpParams = httpParams.set(key, request[key].toString());
            }
        });

        return this.api.get<PagedResult<Connection>>('api/connections', { params: httpParams });
    }

    public delete(id: string): Observable<void> {
        return this.api.delete<void>(`api/connections/${id}`);
    }

    public getById(id: string): Observable<Connection>{
        return this.api.get<Connection>(`api/connections/${id}`);
    }

    public invite(): Observable<{link: string}>{
        return this.api.get<{link: string}>('api/connections/invitation');
    }
}