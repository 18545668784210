import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from '../api';
import { SpinnerService } from '@app/core';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {

  private isRefreshingToken = false;
  private refreshTokenSubject = new BehaviorSubject<any>(null);

  constructor(
    private router: Router,
    private authService: AuthService,
    private spinnerService: SpinnerService
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = this.addAuthenticationToken(req);
    return next.handle(req).pipe(
      catchError((error: any) => {
        const errorResponse = error as HttpErrorResponse;
        const expiredHeader = errorResponse.headers.get('www-authenticate');

        if (error instanceof HttpErrorResponse && error.status === 401) {
          if (expiredHeader && expiredHeader.includes('invalid_token') && !(req.method === "PUT" && req.url.includes('/api/sessions'))) {
            return this.handle401(req, next);
          } else {
            this.isRefreshingToken = false;
            this.authService.clearLocalStorage();
            this.router.navigateByUrl('/authentication');
            return throwError(error);
          }
        } else {
          return throwError(error);
        }
      })
    );
  }

  handle401(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRefreshingToken && localStorage.getItem('access-token')) {
      this.isRefreshingToken = true;
      this.refreshTokenSubject.next(null);
      return this.authService.refresh()
        .pipe(
          switchMap(token => {
            if (token) {
              this.refreshTokenSubject.next(token);
              this.spinnerService.stop();
              return next.handle(this.addAuthenticationToken(request));
            }
            return null;
          }),
          catchError(error => {
            const errorResponse = error as HttpErrorResponse;
            if (errorResponse.status === 401) {
              this.authService.clearLocalStorage();
              this.router.navigate(['authentication'], { queryParams: { returnUrl: this.router.routerState.snapshot.url } });
            }
            return throwError(error);
          }),
          finalize(() => {
            this.isRefreshingToken = false;
          })
        );
    } else {
      return this.refreshTokenSubject.pipe(
        filter(result => result !== null),
        take(1),
        switchMap((res) => {
          this.spinnerService.stop();
          return next.handle(this.addAuthenticationToken(request));
        })
      );
    }
  }

  addAuthenticationToken(request: HttpRequest<any>) {
    const token = localStorage.getItem('access-token');

    return request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }
}
