import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { AppConfig } from '../../models/utils/app-config.model';
import appSettings from '../../../../../assets/config/app-config.json';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  public static settings: AppConfig;
  private http: HttpClient;

  constructor(private handler: HttpBackend) { this.http = new HttpClient(handler); }

  public load() {
    ConfigService.settings = appSettings;
    const jsonFile = `assets/config/app-config.json`;
    return new Promise<void>((resolve, reject) => {
      this.http.get(jsonFile).subscribe({
        next: (response) => {
          ConfigService.settings = response as AppConfig;
          resolve();
        },
        error: (error) => {
          reject(`Could not load file '${jsonFile}': ${ JSON.stringify(error) } `);
        }
      });
    });
  }
}
