import { Injectable } from '@angular/core';

import { ApiService } from '../utils';
import { Permission, AuthorizeAction } from '../../models';
import { Observable } from 'rxjs';
import { shareReplay, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserPermissionService {

  private permissions$: Observable<Permission[]> = new Observable<Permission[]>();

  constructor(private api: ApiService) {
    this.initialize();
  }

  public initialize(): void {
    this.permissions$ = this.api.get<Permission[]>('api/user/permissions').pipe(shareReplay(1));
  }

  public hasPermission(authorizeAction: AuthorizeAction): Observable<boolean> {
    return this.permissions$.pipe(map(permissions => {
      const securityAction = permissions.find(permission => permission.securityAction === authorizeAction.action);

      if (!securityAction) {
        return false;
      }

      return securityAction.securityActionTypes.some(actionType => actionType.value === authorizeAction.type && actionType.allow);
    }));
  }
}
