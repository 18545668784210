export class StateTypes {
    public static readonly INVITED = 'Invited';
    public static readonly NEGOTIATING = 'Negotiating';
    public static readonly CONNECTED = 'Connected';

    public static getStateTypes(): string[] {
        return [
            this.INVITED,
            this.NEGOTIATING,
            this.CONNECTED
        ];
    }
}