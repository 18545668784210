import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Language } from '../../models';
import { ApiService } from '../utils';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  constructor(private api: ApiService) { }

  public get(): Observable<Language[]> {
    return this.api.get('api/languages');
  }
}
